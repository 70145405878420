import React, { useContext, useEffect } from "react";

import { Richtext } from "storyblok-js-client";

import DynamicComponent from "../components";
import PageContext from "../context/PageContext";
import LandingPageLayout from "../layouts/landingPageLayout";
import Layout from "../layouts/layout";
import useStoryblok from "../lib/storyblok";

interface EditorProps {
  location: Location;
}

const EditorPage: React.FC<EditorProps> = ({ location }) => {
  const { story, settings } = useStoryblok(location);

  const pageCtx = useContext(PageContext);
  useEffect(() => {
    pageCtx.set &&
      pageCtx.set({
        settings,
        story,
        pagePath: story?.full_slug,
      });
  }, [settings, story]);

  if (story?.content.component === "landing-page" && settings)
    return (
      <LandingPageLayout settings={settings}>
        <DynamicComponent
          key={story.content._uid}
          content={story.content}
          story={story}
          blok={story.content}
          path={`/${story.full_slug}/`}
        />
      </LandingPageLayout>
    );

  if (story?.content.component === "legal-page" && settings && story.content.azureTermsPrivacy) {
    return (
      <Layout settings={settings}>
        <DynamicComponent
          key={story.content._uid}
          content={story.content}
          story={story}
          blok={story.content}
          path={`/${story.full_slug}/`}
        />
      </Layout>
    );
  }

  if (story && settings)
    return (
      <Layout settings={settings}>
        <DynamicComponent
          key={story.content._uid}
          content={story.content}
          story={story}
          blok={story.content}
          path={`/${story.full_slug}/`}
        />
      </Layout>
    );

  return null;
};

export default EditorPage;
